import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import useInputValidate from 'hooks/useInputValidate';

const Phone = ({ onChange, value, variant, disabled = false, label }) => {
  const [labelStyles, setLabelStyles] = useState(false);
  const { error, validate, setError } = useInputValidate(true);
  const [enhancedPhoneNumber, setEnhancedPhoneNumber] = useState('');
  const countryRef = useRef(null);

  const formatPhoneNumber = (phoneNumber) => {
    setError('');
    let phone;
    if (phoneNumber.charAt(0) === '0') phone = phoneNumber.slice(1);
    else phone = phoneNumber;
    return phone;
  };

  const handleChange = (val, country) => {
    setEnhancedPhoneNumber(val.slice(country.dialCode.length));
    countryRef.current = country;
  };

  useEffect(() => {
    // Perform side effects after the render phase
    if (enhancedPhoneNumber && onChange) {
      onChange({
        ...value,
        phoneNumber: formatPhoneNumber(enhancedPhoneNumber),
        countryCode: '+' + countryRef.current.dialCode,
      });
    }
  }, [enhancedPhoneNumber]);

  return (
    <div className={`flex flex-col ${variant || 'mb-6'}`}>
      <div className="relative">
        <motion.label
          htmlFor="phone_number"
          animate={{
            scale: 0.8,
            top: !labelStyles && !value?.phoneNumber ? '8px left-80' : '-16px',
            fontSize: !labelStyles && !value?.phoneNumber ? '16px' : '14px',
            display: !labelStyles && !value?.phoneNumber ? 'none' : 'block',
          }}
          className="
                    absolute text-neutral_body mb-2 block text-14
                    font-campton_r bg-neutral_white px-1 pt-2 cursor-text z-10"
        >
          {label || 'Phone number'}
        </motion.label>
        <PhoneInput
          placeholder="Phone number"
          containerStyle={{
            width: '100%',
            borderRadius: '8px',
          }}
          inputProps={{
            disabled: disabled,
            id: 'phone_number',
          }}
          buttonStyle={{
            borderBottomLeftRadius: '8px',
            borderTopLeftRadius: '8px',
            borderRight: '0px',
            backgroundColor: disabled ? '#F2F3F3' : '#ffffff',
          }}
          inputStyle={{
            borderRadius: '8px',
            width: '100%',
            color: '#131515',
            border: '1px solid #DFE2E2',
            height: '56px',
            fontFamily: ['campton_r', 'sans-serif'],
            fontSize: '14px',
            boxShadow: '',
            backgroundColor: disabled ? '#F2F3F3' : '#ffffff',
          }}
          // disableCountryCode= {true}
          id="phone_number"
          name="phone_number"
          onChange={(value, country) => handleChange(value, country)}
          onBlur={() => validate({ name: 'phone_number', value: enhancedPhoneNumber })}
          onFocus={() => setLabelStyles(true)}
          value={`${value?.countryCode}${value?.phoneNumber}`}
          country={'ng'}
          countryCodeEditable={false}
          enableSearch
          required
          defaultMask="...-...-...."
          defaultErrorMessage="sfsfsfs"
        />
      </div>
      {error && <p className={`font-campton_r text-error text-12 mt-[4px]`}>{error}</p>}
    </div>
  );
};

export default Phone;
Phone.propTypes = {
  value: PropTypes.object,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
};
