import { createSlice } from '@reduxjs/toolkit';
import {
  getLabTests,
  // getMedications,
  getVisits,
  getVitals,
  getProcedure,
  getReferrals,
  getTherapies,
  getPostOperativeNotes,
  getOperativePlan,
  getOthersNotes,
  getVisitAnalytics,
  getProgressNotes,
} from './actions';
import { getPrescriptions } from 'store/modules/pharmacy/actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  vitals: {},
  labTest: {},
  visits: {},
  selectedVisit: {},
  services: [],
  medications: {},
  procedures: {},
  referrals: {},
  therapies: {},
  postOperativeNote: {},
  postOperativePlan: {},
  othersNote: {},
  analytics: [],
  progressNotes: {},
};

export const clinicals = createSlice({
  name: 'clinicals',
  initialState,
  reducers: {
    setVisit: (state = initialState, action) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVitals.fulfilled, (state, action) => {
        state.vitals = action?.payload?.data;
      })
      .addCase(getLabTests.fulfilled, (state, action) => {
        state.labTest = action?.payload?.data;
      })
      .addCase(getVisits.fulfilled, (state, action) => {
        state.visits = action?.payload?.data;
      })
      .addCase(getVisitAnalytics.fulfilled, (state, action) => {
        state.analytics = action?.payload?.data;
      })
      .addCase(getPrescriptions.fulfilled, (state, action) => {
        state.medications = action?.payload?.data;
      })
      .addCase(getProcedure.fulfilled, (state, action) => {
        state.procedures = action?.payload?.data;
      })
      .addCase(getReferrals.fulfilled, (state, action) => {
        state.referrals = action?.payload?.data;
      })
      .addCase(getTherapies.fulfilled, (state, action) => {
        state.therapies = action?.payload?.data;
      })
      .addCase(getPostOperativeNotes.fulfilled, (state, action) => {
        state.postOperativeNote = action?.payload?.data;
      })
      .addCase(getOperativePlan.fulfilled, (state, action) => {
        state.postOperativePlan = action?.payload?.data;
      })
      .addCase(getOthersNotes.fulfilled, (state, action) => {
        state.othersNote = action?.payload?.data;
      })
      .addCase(getProgressNotes.fulfilled, (state, action) => {
        state.progressNotes = action?.payload?.data;
      });
  },
});
// Selectors
const selectClinicals = (state) => state.clinicals;
//Reducers and actions
export const { setVisit } = clinicals.actions;
//App Redux State
export const useClinicalState = () => useAppSelector(selectClinicals);
export default clinicals.reducer;
