import { institution } from 'services/endpoints/institution';
import { createCustomAsyncThunk } from 'utils/createThunk';

const getActivityLog = createCustomAsyncThunk('locations/getActivityLog', 'get', institution.activity_log);
const getActivityLogDetails = createCustomAsyncThunk(
  'locations/getActivityLogDetails',
  'get',
  institution.activity_log,
);
const getLocations = createCustomAsyncThunk('locations/getLocation', 'get', institution.locations);
const getLocationDetails = createCustomAsyncThunk('locations/getLocationDetails', 'get', institution.institution);
const updateLocation = createCustomAsyncThunk('locations/updateLocation', 'patch', institution.institution);

export { getActivityLog, getActivityLogDetails, getLocations, getLocationDetails, updateLocation };
