import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useInputValidate from '../../hooks/useInputValidate';
import { motion } from 'framer-motion';
import Avatar from 'components/Avatar/Avatar';

export default function SearchDropDown({
  id,
  name,
  label,
  placeholder,
  searchValue,
  helperText,
  onChange,
  onSelect, // Function to handle when a name is selected from the list
  options, // The array of search results (matching patients)
  showError,
  disabled,
  variant,
  measurement,
}) {
  const { error } = useInputValidate(showError);
  const [display, setDisplay] = useState(false);
  const ref = useRef(null);

  const inputError = useMemo(() => {
    return !(showError === false || !error);
  }, [error]);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplay(false); // Close dropdown if clicked outside
      }
    };

    // Attach the event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  // Only display dropdown if searchValue is not empty
  const shouldDisplayDropdown = display && searchValue.trim().length > 0;

  return (
    <>
      <div ref={ref} className="relative mb-[3.5px] input-container">
        <input
          id={id}
          name={name}
          type="text"
          placeholder={placeholder || ''}
          value={searchValue}
          onChange={onChange}
          disabled={disabled}
          onClick={() => !disabled && setDisplay(true)} // Open dropdown on click
          className={`${variant}
            ${inputError ? 'border-error' : 'border-neutral_stroke'} 
            h-[56px] px-4 text-neutral_black text-14 w-full outline-0 border font-campton_r hide_tap
            rounded-lg focus:border-brand_primary focus:border
            ${disabled ? 'bg-neutral_disabled border-neutral_stroke_2' : 'bg-neutral_white'} 
          `}
        />

        {label && (
          <label
            htmlFor={id}
            className={`
            ${disabled ? 'bg-none' : 'bg-neutral_white'}
            text-neutral_body mb-2 font-campton_r px-1 pt-2 cursor-text ${variant}`}
          >
            {label}
          </label>
        )}

        {measurement && (
          <div className="flex items-center absolute top-0 right-[12px] h-full">
            <div className="text-14 text-neutral_grey font-campton_r">{measurement}</div>
          </div>
        )}

        {/* Dropdown for matching patient names */}
        {shouldDisplayDropdown && (
          <motion.div
            className={`absolute w-full z-20 border rounded-lg shadow-lg max-h-48 overflow-y-auto`}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            style={{
              backgroundColor: '#fff',
              borderColor: '#36A1C5',
              borderTop: 'none',
              borderTopLeftRadius: '0',
              borderTopRightRadius: '0',
              marginTop: '-6px',
            }}
          >
            {options.length ? (
              options.map((option) => (
                <div
                  key={option.id}
                  className="mx-3 py-4 cursor-pointer flex items-center gap-3 border-b border-[#ECEEEE] last:border-b-0"
                  onClick={() => {
                    onSelect(option); // Select the patient
                    setDisplay(false); // Close the dropdown
                  }}
                >
                  <Avatar
                    url=""
                    width="32px"
                    height="32px"
                    //   alt={item?.firstName?.split('')[0]}
                    initials={option.firstName?.split('')[0] + option.lastName?.split('')[0]}
                    className="w-[32px] h-[32px]"
                  />
                  <div className="font-light">
                    <p className="text-[#17181C]">{option.firstName + ' ' + option.lastName}</p>
                    <p className="text-12 text-neutral_body ">
                      {option.emailAddress} | {option.phoneNumber}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="mx-3 py-3 text-neutral_body text-14">No results found</div>
            )}
          </motion.div>
        )}
      </div>
      <p className={`font-campton_r ${inputError ? 'text-error' : 'text-neutral_body'} text-12`}>
        {(inputError && error) || helperText}
      </p>
    </>
  );
}

SearchDropDown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired, // Function to handle patient selection
  options: PropTypes.array.isRequired, // Array of search results (patient names)
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  showError: PropTypes.bool,
  searchValue: PropTypes.any,
  measurement: PropTypes.any,
};
