import React from "react"
import PropTypes from "prop-types"
import SubMenu from "../SubMenu"
import { ReactComponent as Prescriptions } from 'assets/icons/prescriptions.svg';
import { ReactComponent as Inventory } from 'assets/icons/inventory.svg';
import usePermission from "hooks/usePermission";

export default function PharmacyOption({ showDrawer, togglePharmacy, iconMargin }) {
  const { hasModulePermission } = usePermission()

  const settings_options = [
    {
      name: 'Prescriptions',
      value: 'prescription',
      icon: <Prescriptions className={iconMargin} fill="#FFFFFF" />,
      permission: hasModulePermission('Pharmacy'),
    },
    {
      name: 'Inventory',
      value: 'inventory',
      icon: <Inventory className={`${iconMargin} w-[20px]`} fill="#FFFFFF" />,
      permission: hasModulePermission('Inventory'),
    },
  ];

  return (
    <SubMenu
      options={settings_options}
      condition={togglePharmacy}
      type='pharmacy'
      showDrawer={showDrawer}
    />
  )
}

PharmacyOption.propTypes = {
  showDrawer: PropTypes.bool,
  togglePharmacy: PropTypes.bool,
  iconMargin: PropTypes.string
}