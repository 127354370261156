import { inventory, prescription } from 'services/endpoints/pharmacy';
import { createCustomAsyncThunk, customAsyncThunk } from 'utils/createThunk';

const getDashbaordStats = createCustomAsyncThunk('inventory/getDashbaordStats', 'get', inventory.dashboard_stats);
const getProducts = createCustomAsyncThunk('inventory/getProducts', 'get', inventory.products);
const getStockHistory = createCustomAsyncThunk('inventory/getStockHistory', 'get', inventory.stock_history);
const getDispenseHistory = createCustomAsyncThunk('inventory/getDispenseHistory', 'get', prescription.dispense_history);
const deleteStockHistory = createCustomAsyncThunk('inventory/deleteStockHistory', 'delete', inventory.stock_history);
const createProduct = createCustomAsyncThunk('inventory/createProduct', 'post', inventory.single_product);
const addBulkProduct = createCustomAsyncThunk('inventory/addBulkProduct', 'post', inventory.bulk_product);
const deleteProduct = createCustomAsyncThunk('inventory/deleteProduct', 'delete', inventory.single_product);
const editProduct = createCustomAsyncThunk('inventory/editProduct', 'patch', inventory.single_product);
const restockProduct = createCustomAsyncThunk('inventory/restockProduct', 'post', inventory.restock);
const getCategory = createCustomAsyncThunk('inventory/getCategory', 'get', inventory.category);
const createCategory = createCustomAsyncThunk('inventory/createCategory', 'post', inventory.category);
const editCategory = createCustomAsyncThunk('inventory/editCategory', 'patch', inventory.category);
const deleteCategory = createCustomAsyncThunk('inventory/deleteCategory', 'delete', inventory.category);
const getStockControl = createCustomAsyncThunk('inventory/getStockControl', 'get', inventory.stock_control);
const createStockControl = createCustomAsyncThunk('inventory/createStockControl', 'post', inventory.stock_control);
const deleteStockControl = createCustomAsyncThunk('inventory/deleteStockControl', 'delete', inventory.stock_control);
const getPrescriptions = createCustomAsyncThunk('prescription/getPrescriptions', 'get', prescription.prescription);
const getPatientPrescriptions = createCustomAsyncThunk('prescription/getPatientPrescriptions', 'get', prescription.prescription_history);
const getPrescriptionsDetails = createCustomAsyncThunk('prescription/getPrescriptionsDetails', 'get', prescription.prescription);
const createPrescription = createCustomAsyncThunk('prescription/createPrescription', 'post', prescription.prescription);
const editPrescription = createCustomAsyncThunk('prescription/editPrescription', 'patch', prescription.prescription);
const dispenseMedication = createCustomAsyncThunk('prescription/dispenseMedication', 'patch', prescription.medication);
const dispenseAllMedication = createCustomAsyncThunk('prescription/dispenseAllMedication', 'patch', prescription.dispense_all_medication);
const declinePrescription = createCustomAsyncThunk('prescription/declinePrescription', 'patch', prescription.medication);
const declineAllPrescription = createCustomAsyncThunk('prescription/declineAllPrescription', 'patch', prescription.decline_all_medication);
const reverseMedication = createCustomAsyncThunk('prescription/reverseMedication', 'patch', prescription.medication);

export {
  getDashbaordStats,
  getProducts,
  getStockHistory,
  getDispenseHistory,
  deleteStockHistory,
  createProduct,
  addBulkProduct,
  deleteProduct,
  getStockControl,
  createStockControl,
  deleteStockControl,
  editProduct,
  restockProduct,
  getCategory,
  createCategory,
  editCategory,
  deleteCategory,
  getPrescriptions,
  getPatientPrescriptions,
  getPrescriptionsDetails,
  createPrescription,
  editPrescription,
  dispenseMedication,
  dispenseAllMedication,
  declinePrescription,
  declineAllPrescription,
  reverseMedication,
};