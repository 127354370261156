const base = '/institution';

export const patient = {
  patients: `${base}/patients`,
  get_patients_analytics: `${base}/patients/chart`,
  get_in_patients_analytics: `/in-patient/count`,
  in_patient: `/in-patient`,
  high_risk_in_patient: `/in-patient/high-risk`,
  procedure: '/procedure',
  referral: '/referral',
  therapy: '/therapy',
  patient_profile: '/patient-profile',
  others_note: `/others`,
  operative_note: `/postOperative`,
  operative_plan: `/preOperative`,
};
