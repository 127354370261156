const base = '/institution';

export const institution = {
  activity_log: '/logs',
  institution: `${base}`,
  get_visit_chart: `${base}/visit-chart`,
  create_vitals: `${base}/visit/add-vital-sign`,
  vitals: `${base}/vitals`,
  visits: `${base}/visit`,
  visit_summary: '/visit-summary',
  scan_findings: '/scan-finding',
  chief_complaint: '/chief-complaint',
  update_medical_record: `/patient-profile/medical-records`,
  test_types: `${base}/test-types`,
  lab_test: `${base}/lab-test`,
  locations: `${base}/locations`,
  get_appointments: `${base}/appointments`,
  create_appointment: '/appointment/clinical',
  cancel_appointment: '/appointment',
  get_specialty: `${base}/specialties`,
  add_patient: `${base}/patient-signup`,
  add_bulk_patient: `${base}/patient-signup/bulk-upload`,
  update_patient: `${base}/patients`,
};
