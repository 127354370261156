const inventoryBase = '/inventory';
const prescriptionBase = '/pharmacy/prescription';
const medicationBase = '/medications';

export const inventory = {
  dashboard_stats: `${inventoryBase}/dashboard`,
  products: `${inventoryBase}/products`,
  stock_history: `${inventoryBase}/product/restock`,
  category: `${inventoryBase}/category`,
  single_product: `${inventoryBase}/category/product`,
  bulk_product: `${inventoryBase}/category/products/bulk`,
  restock: `${inventoryBase}/product/restock`,
  stock_control: `${inventoryBase}/stock-control`,
};

export const prescription = {
  prescription: `${prescriptionBase}`,
  prescription_history: `${prescriptionBase}/history`,
  medication: `${medicationBase}/patient`,
  dispense_all_medication: `${medicationBase}/dispense-all`,
  decline_all_medication: `${medicationBase}/decline-all`,
  dispense_history: `${medicationBase}/dispense/history`,
  // prescription_details: `${prescriptionBase}/details`,
  // category: `${prescriptionBase}/category`,
};
