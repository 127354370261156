import React from "react"
import PropTypes from "prop-types"
import SubMenu from "../SubMenu"
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as Profile } from 'assets/icons/hospital.svg';
import { ReactComponent as Patient } from 'assets/icons/patient.svg';
import { ReactComponent as Roles } from 'assets/icons/admin-officer.svg';
import { ReactComponent as Policies } from 'assets/icons/policies.svg';
import { ReactComponent as Specialty } from 'assets/icons/specialty.svg';
import { ReactComponent as Pulse } from 'assets/icons/pulse.svg';
import { useAuthState } from "store/modules/auth";
import usePermission from "hooks/usePermission";

export default function SettingsOption({ showDrawer, toggleSettings, iconMargin }) {
  const { user } = useAuthState()
  const { hasModulePermission } = usePermission()

  const settings_options = [
    {
      name: user?.businessName ? 'Organization Profile' : 'Profile',
      value: 'profile',
      icon: <Profile className={iconMargin} fill="#FFFFFF" />,
      permission: true,
    },
    {
      name: 'Roles & Permissions',
      value: 'roles-and-permissions',
      icon: <Roles className={`${iconMargin} w-[20px]`} fill="#FFFFFF" />,
      permission: hasModulePermission('Roles and Permission'),
    },
    {
      name: 'Staff Management',
      value: 'staff-management',
      icon: <Patient className={iconMargin} />,
      permission: hasModulePermission('Staff management'),
    },
    {
      name: 'Locations',
      value: 'locations',
      icon: <Location className={iconMargin} fill="#FFFFFF" />,
      permission: hasModulePermission('Location'),
    },
    {
      name: 'Specialty',
      value: 'specialty',
      icon: <Specialty className={iconMargin} fill="#FFFFFF" />,
      permission: hasModulePermission('Specialty'),
    },
    {
      name: 'Policies',
      value: 'policies',
      icon: <Policies className={iconMargin} fill="#FFFFFF" />,
      permission: hasModulePermission('Policy'),
    },
    {
      name: 'Activity Log',
      value: 'activity-log',
      icon: <Pulse className={iconMargin} fill="#FFFFFF" />,
      permission: true,
    },
  ];

  return (
    <SubMenu
      options={settings_options}
      condition={toggleSettings}
      type='settings'
      showDrawer={showDrawer}
    />
  )
}

SettingsOption.propTypes = {
  showDrawer: PropTypes.bool,
  toggleSettings: PropTypes.bool,
  iconMargin: PropTypes.string
}