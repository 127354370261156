import { createSlice } from '@reduxjs/toolkit';
import { getProducts, getCategory, getDashbaordStats, getPrescriptions } from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  products: [],
  prescriptions: [],
  categories: [],
  dashboardStats: {},
  selectedProduct: {},
  selectedCategory: {},
  selectedPrescription: {},
  selectedPatientPrescription: {},
  // serviceDetails: {},
};

export const pharmacy = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {
    setProduct: (state = initialState, action) => {
      state.selectedProduct = action.payload;
    },
    setCategory: (state = initialState, action) => {
      state.selectedCategory = action.payload;
    },
    setPrescription: (state = initialState, action) => {
      state.selectedPrescription = action.payload;
    },
    setPatientPrescription: (state = initialState, action) => {
      state.selectedPatientPrescription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action?.payload?.data;
      })
      .addCase(getPrescriptions.fulfilled, (state, action) => {
        state.prescriptions = action?.payload?.data;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categories = action?.payload?.data;
      })
      .addCase(getDashbaordStats.fulfilled, (state, action) => {
        state.dashboardStats = action?.payload?.data;
      });
  },
});

// Selectors
const selectProducts = (state) => state.pharmacy;
//Reducers and actions
export const { setProduct, setCategory, setPrescription, setPatientPrescription } = pharmacy.actions;
//App Redux State
export const usePharmacyState = () => useAppSelector(selectProducts);
export default pharmacy.reducer;
