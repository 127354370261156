import { createSlice } from '@reduxjs/toolkit';
import { getLocationDetails, getLocations, getActivityLog, getActivityLogDetails } from './actions';
import { useAppSelector } from 'hooks/useReduxHook';

const initialState = {
  activityLog: {},
  activityLogDetails: {},
  locations: {},
  locationDetails: {},
};

export const locations = createSlice({
  name: 'locations',
  initialState,
  extraReducers: (builder) => {
    builder
      //GET ACTIVITY LOG
      .addCase(getActivityLog.fulfilled, (state, action) => {
        state.activityLog = action?.payload?.data;
      })
      //GET ACTIVITY LOG DETAILS
      .addCase(getActivityLogDetails.fulfilled, (state, action) => {
        state.activityLogDetails = action?.payload?.data;
      })
      //GET LOCATIONS
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locations = action?.payload?.data;
      })
      //GET A LOCATION DETAILS
      .addCase(getLocationDetails.fulfilled, (state, action) => {
        state.locationDetails = action?.payload?.data;
      });
  },
});
// Selectors
const selectLocations = (state) => state.locations;
//App Redux State
export const useLocationState = () => useAppSelector(selectLocations);

export default locations.reducer;
